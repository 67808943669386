import React from 'react';
import { useState, useEffect } from 'react';
import { getNextGameDateUTC } from '../../helpers';
import { Typography } from '@mui/material';

type TimeLeft = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

interface IProps {
  currentGameDate: string;
}

export default function CountdownTimer(props: IProps) {
  const calculateTimeLeft = (): TimeLeft => {
    const now = new Date();
    const target = getNextGameDateUTC(props.currentGameDate); // Ensure targetDate is a Date object
    const diff = target.getTime() - now.getTime(); // Use getTime() to get milliseconds

    if (diff <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    return {
      days: Math.floor(diff / (1000 * 60 * 60 * 24)),
      hours: Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((diff % (1000 * 60)) / 1000),
    };
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const tick = () => setTimeLeft(calculateTimeLeft());

    const interval = setInterval(tick, 1000);
    return () => clearInterval(interval); // Cleanup on unmount
  }, [props.currentGameDate]); // Runs when targetDate changes

  return (
    <Typography fontWeight={'bold'} className='countdown padding-bottom'>
      {'Next game: '}
      {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
    </Typography>
  );
}
