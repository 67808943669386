export function formatLongDisplayDate(dateString: string): string {
    const months = [
        "Jan", "Feb", "March", "April", "May", "June", 
        "July", "Aug", "Sept", "Oct", "Nov", "Dec"
    ];
    const [year, month, day] = dateString.split('-');
    const monthName = months[parseInt(month) - 1];
    return `${monthName} ${parseInt(day)}, ${year}`;
}

export function getNextGameDateUTC(currentGameDateString:string){
    const parts = currentGameDateString.split('-'); // Split the YYYY-MM-DD string
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Months are 0-indexed in JS Date
    const day = parseInt(parts[2], 10);

    const hour = 12; //Cron job runs at 12pm UTC

    const date = new Date(Date.UTC(year, month, day, hour));
    return date
}